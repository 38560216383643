<template>
    <main-layout>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center" class="ma-1">
                <v-col cols="12" sm="8" md="4">
                    <div class="font-weight-light primary--text mb-6 text-center">
                        <p class="mt-3" v-if="!errors">Change your password here</p>
                        <p class="mt-3 red--text" v-else>{{errors.password[0]}}</p>
                        <v-alert v-if="alert.text" dense text :type="alert.type">
                            {{alert.text}}
                        </v-alert>
                    </div>
                    <v-form v-model="valid" ref="form">
                        <v-text-field  label="Old Password"
                                       class="form-control"
                                       :rules="rules.fieldRules"
                                       v-model="form.current_password"
                                       :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                       :type="!e1 ? 'password' : 'text'"
                                       @click:append="() => (e1 = !e1)"
                                       prepend-icon="lock"/>
                        <v-text-field  label="New Password"
                                       class="form-control"
                                       :rules="rules.passwordRules"
                                       v-model="form.new_password"
                                       :append-icon="e2 ? 'visibility' : 'visibility_off'"
                                       :type="!e2 ? 'password' : 'text'"
                                       @click:append="() => (e2 = !e2)"
                                       prepend-icon="lock"/>
                        <v-text-field  label="Retype New Password"
                                       class="form-control"
                                       :rules="[(form.new_password === form.new_confirm_password) || 'Password not match']"
                                       v-model="form.new_confirm_password"
                                       :append-icon="e3 ? 'visibility' : 'visibility_off'"
                                       :type="!e3 ? 'password' : 'text'"
                                       @click:append="() => (e3 = !e3)"
                                       prepend-icon="lock"/>
                        <v-btn block class="mt-5" :loading="change_password" :disabled="change_password" color="primary" @click.prevent="changePassword">
                            Change Password
                            <template v-slot:loader>
                                <span>Changing your password</span>
                            </template>
                        </v-btn>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </main-layout>
</template>

<script>
    import user from "../../api/user";
    export default {
        name: "Security",
        data() {
            return {
                title: "Security",
                e1: false,
                e2: false,
                e3: false,
                valid: false,
                loading: false,
                message: '',
                change_password: false,
                errors: null,
                form: {
                    current_password: '',
                    new_password: '',
                    new_confirm_password: '',
                    id: '',
                },
                rules: {
                    fieldRules: [
                        v => !!v || 'This field is required',
                    ],
                    passwordRules: [
                        v => !!v || 'Password is required',
                        v => (v && v.length >= 5) || 'Password must have at least 8  characters',
                        v => /(?=.*[A-Z])/.test(v) || 'Must have one uppercase character',
                        v => /(?=.*\d)/.test(v) || 'Must have one number',
                        v => /([!@$%_])/.test(v) || 'Must have one special character'
                    ],
                }
            }
        },
        computed: {
            user(){
                return this.$store.getters.getCurrentUser;
            },
            alert(){
                return this.$store.getters.getAlert;
            }
        },
        methods: {
            changePassword(){
                if(this.$refs.form.validate()) {
                    this.change_password = true;
                    this.form.id = this.user.employee_id;
                    user.change_password(this.form)
                        .then(response =>{
                            this.$store.dispatch('setAlert',response.data);
                            this.change_password = false;
                            localStorage.removeItem('token');
                            this.$router.push({name: "Welcome"})
                        })
                        .catch(error =>{
                            this.errors = error.response.data.errors;
                            this.change_password = false;
                        });
                }
            },
        },
        created(){
            this.$store.commit('setAlert','');
        }
    }
</script>

<style scoped>

</style>