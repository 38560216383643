import Api from "./Api";

export default {
    payrollslip(form){
        return Api().post("/get_payroll_detail",form)
    },
    payrolllist(form){
        return Api().post("/get_payroll_list",form)
    },
    adminpayrolllist(){
        return Api().post("/get_admin_payroll_list")
    },
    upload_payroll(data){
        return Api().post("/upload_payroll",data)
    },
    edit_payroll(data){
        return Api().post("/edit_payroll",data)
    },
    delete_payroll(data){
        return Api().post("/delete_payroll",data)
    }
}