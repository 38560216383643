<template>
    <main-layout>
        <v-container class="py-8 px-6" fluid>
            <v-row>
                <v-col>
                    <v-alert v-if="alert2.text" dense text :type="alert2.type">
                        {{alert2.text}}
                    </v-alert>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card class="pa-3">
                        <v-card-title>
                            Payroll List
                            <v-spacer></v-spacer>
                            <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Search"
                                    single-line
                                    hide-details
                            ></v-text-field>
                        </v-card-title>
                        <v-data-table
                                :headers="headers"
                                :items="items"
                                :search="search"
                                item-key="payroll_number"
                        >
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-spacer></v-spacer>
                                    <v-dialog
                                            v-model="dialog"
                                            max-width="900px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                    color="primary"
                                                    dark
                                                    class="ma-2"
                                                    v-bind="attrs"
                                                    v-on="on"
                                            >
                                                New Payroll
                                            </v-btn>
                                            <v-btn
                                                    text
                                                    color="primary"
                                                    dark
                                                    class="ma-2"
                                                    @click="downloadTemplate"
                                            >
                                                <v-icon class="mr-2">mdi-download</v-icon>
                                                Download Template
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-overlay
                                                    :absolute="absolute"
                                                    :value="overlay"
                                            >
                                                <v-progress-circular :size="50" :width="5" color="white" indeterminate></v-progress-circular>
                                            </v-overlay>
                                            <v-card-title>
                                                <span class="headline">{{ formTitle }}</span>
                                            </v-card-title>

                                            <v-card-text>
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12"> <v-alert v-if="alert.text" dense text :type="alert.type">
                                                            {{alert.text}}
                                                        </v-alert></v-col>
                                                        <v-col cols="8" >
                                                            <v-text-field v-model="editedItem.title" label="Title"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="6" >
                                                            <v-text-field v-model="editedItem.entity_name" label="Entity Name (Optional)"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="6" >
                                                            <v-text-field v-model="editedItem.fund_cluster" label="Fund Cluster (Optional)"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" >
                                                            <v-text-field v-model="editedItem.description" label="Description (Optional)"></v-text-field>
                                                        </v-col>
                                                        <v-col v-if="isEdit" cols="12" >
                                                            <input type="file" ref="inputFile" id="csv_file" name="csv_file" class="form-control" @change="loadCSV($event)">
                                                            <v-simple-table>
                                                                <template v-slot:default>
                                                                    <thead>
                                                                    <tr>
                                                                        <th v-for="key in parse_header"
                                                                            @click="sortBy(key)"
                                                                            v-bind:key="key"
                                                                            :class="{ active: sortKey == key }">
                                                                            {{ key | capitalize }}
                                                                            <span class="arrow" :class="sortOrders[key] > 0 ? 'asc' : 'dsc'"></span>
                                                                        </th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    <tr v-for="csv in parse_csv" v-bind:key="csv">
                                                                        <td v-for="key in parse_header" v-bind:key="key">
                                                                            {{csv[key]}}
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </template>
                                                            </v-simple-table>
                                                        </v-col>

                                                    </v-row>
                                                </v-container>
                                            </v-card-text>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="primary darken-1" text @click="close">
                                                    Cancel
                                                </v-btn>
                                                <v-btn color="primary darken-1" text @click="save">
                                                    Save
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                    <v-dialog v-model="dialogDelete" max-width="550px">
                                        <v-card>
                                            <v-card-title class="headline">Are you sure you want to delete this payroll? <i>(This action cannot be undone)</i></v-card-title>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="green darken-1" text @click="closeDelete">Cancel</v-btn>
                                                <v-btn color="red darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon
                                        small
                                        color="primary"
                                        class="mr-2"
                                        @click="editItem(item)"
                                >
                                    mdi-pencil
                                </v-icon>
                                <v-icon
                                        small
                                        color="red"
                                        @click="deleteItem(item)"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>
                            <template v-slot:no-data>
                                No available data
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </main-layout>
</template>

<script>
    import user from "../../api/user";
    import payrolls from "../../api/payrolls";
    export default {
        name: "Panel",
        data () {
            return {
                absolute: true,
                overlay: false,
                dialog: false,
                dialogDelete: false,
                search: '',
                singleSelect: false,
                selected: [],
                items: [],
                parse_header: [],
                parse_csv: [],
                sortOrders:{},
                sortKey: '',
                csv_file: null,
                alert:{},
                toDelete: null,
                alert2:{},
                headers: [
                    {
                        text: 'Payroll No.',
                        align: 'start',
                        filterable: true,
                        value: 'payroll_number',
                    },
                    { text: 'Title', value: 'title' },
                    { text: 'Entity Name', value: 'entity_name' },
                    { text: 'Fund Cluster', value: 'fund_cluster' },
                    { text: 'Description', value: 'description' },
                    { text: 'Date Uploaded', value: 'created_at' },
                    { text: 'Actions', value: 'actions', sortable: false },
                ],
                editedIndex: -1,
                editedItem: {
                    payroll_number: '',
                    title: '',
                    entity_name: '',
                    fund_cluster: '',
                    description: '',
                },
                defaultItem: {
                    payroll_number: '',
                    title: '',
                    entity_name: '',
                    fund_cluster: '',
                    description: '',
                },

            }
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'New Payroll' : 'Edit Payroll'
            },
            isEdit(){
                return this.editedIndex === -1 ? true : false
            }
        },
        watch: {
            dialog (val) {
                val || this.close()
            },
            dialogDelete (val) {
                val || this.closeDelete()
            },
        },
        filters: {
            capitalize: function (str) {
                return str.charAt(0).toUpperCase() + str.slice(1)
            }
        },
        methods: {
            downloadTemplate(){
                window.location.href = 'http://minscat.edu.ph/files/payroll_template_v2.csv'
            },
            load_data() {
                if(!this.$store.getters.getPayrollList){
                    user.auth()
                        .then(() =>{
                            payrolls.adminpayrolllist()
                                .then(response =>{
                                    this.items = response.data;
                                })
                        })
                }

            },
            editItem (item) {
                this.editedIndex = this.items.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            deleteItem (item) {
                this.toDelete = item.payroll_number
                this.editedIndex = this.items.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            deleteItemConfirm () {
                this.overlay = true;
                const data = {
                    payroll_number: this.toDelete,
                }
                user.auth()
                    .then(() => {
                        payrolls.delete_payroll(data).then(response => {
                            this.alert2 =
                                {
                                    type: response.data.type,
                                    text: response.data.message,
                                }
                            this.overlay = false;
                            this.load_data();
                        })
                    })

                this.items.splice(this.editedIndex, 1)
                this.closeDelete()
            },

            close () {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
                this.clear();
            },
            clear(){
                this.alert2 = {};
                this.alert = {};
                this.parse_csv = [];
                this.parse_header = [];
                this.$refs.inputFile.value = "";
            },
            closeDelete () {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },
            save () {
                this.overlay = true;
                this.alert = {};
                if (this.editedIndex > -1) {
                    if(this.editedItem.title !== ""){
                        const data = {
                            payroll: this.editedItem,
                        }
                        user.auth()
                            .then(() =>{
                                payrolls.edit_payroll(data).then( response => {
                                    if(response.data.type === 'success'){
                                        if(response.data.type === 'error'){
                                            this.alert =
                                                {
                                                    type: response.data.type,
                                                    text: response.data.message,
                                                }
                                        }
                                        if(response.data.type === 'success'){
                                            this.alert2 =
                                                {
                                                    type: response.data.type,
                                                    text: response.data.message,
                                                }
                                            this.overlay = false;
                                            this.load_data();
                                            this.close()
                                        }
                                    }
                                })
                            })
                    }
                    else{
                        this.overlay = false;
                        this.alert =
                            {
                                type: 'error',
                                text: 'Please fill-up the needed information first!',
                            }
                    }
                } else {
                    //this.items.push(this.editedItem)
                    if(this.parse_csv.length > 0 && this.editedItem.title !== ""){
                        const data = {
                            csv_headers: this.parse_header,
                            csv_body: this.parse_csv,
                            payroll: this.editedItem,
                        }
                        user.auth()
                            .then(() =>{
                                payrolls.upload_payroll(data)
                                    .then(response =>{
                                        if(response.data.type === 'error'){
                                            this.alert =
                                                {
                                                    type: response.data.type,
                                                    text: response.data.message,
                                                }
                                        }
                                        if(response.data.type === 'success'){
                                            this.alert2 =
                                                {
                                                    type: response.data.type,
                                                    text: response.data.message,
                                                }
                                            this.overlay = false;
                                            this.load_data();
                                            this.close()
                                        }

                                    })
                                    .catch(error =>{
                                        var msg = error.response.data.message;
                                        var err = msg.split(':')
                                        if(err[0] === 'SQLSTATE[42S22]'){
                                            // this.$swal({
                                            //     icon: 'error',
                                            //     text: 'Looks like there something wrong with your template. Please check your template and try again.',
                                            //     confirmButtonColor: "red"
                                            // });
                                            this.overlay = false;
                                        }
                                    })
                            })
                    }
                    else{
                        this.overlay = false;
                        this.alert =
                            {
                                type: 'error',
                                text: 'Please fill-up the needed information first!',
                            }
                    }
                }
            },
            sortBy: function (key) {
                var vm = this
                vm.sortKey = key
                vm.sortOrders[key] = vm.sortOrders[key] * -1
            },
            csvJSON(csv){
                var vm = this
                var lines = csv.split("\n")
                var result = []
                var headers = lines[0].split(",")
                vm.parse_header = lines[0].split(",")
                lines[0].split(",").forEach(function (key) {
                    vm.sortOrders[key] = 1
                })

                lines.map(function(line, indexLine){
                    if (indexLine < 1) return // Jump header line

                    var obj = {}
                    var currentline = line.split(",")

                    headers.map(function(header, indexHeader){
                        obj[header] = currentline[indexHeader]
                    })

                    result.push(obj)
                })

                result.pop() // remove the last item because undefined values

                return result // JavaScript object
            },
            loadCSV(e) {
                var vm = this
                if (window.FileReader) {
                    var reader = new FileReader();
                    reader.readAsText(e.target.files[0]);
                    // Handle errors load
                    reader.onload = function(event) {
                        var csv = event.target.result;
                        vm.parse_csv = vm.csvJSON(csv)

                    };
                    reader.onerror = function(evt) {
                        if(evt.target.error.name == "NotReadableError") {
                            alert("Canno't read file !");
                        }
                    };
                } else {
                    alert('FileReader are not supported in this browser.');
                }
            }
        },
        mounted() {
            this.load_data();
        },
    }
</script>

<style scoped>

</style>