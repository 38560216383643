<template>
    <v-container class="fill-height " fluid>
        <v-row align="center" justify="center">
            <v-col  cols="12" sm="8" md="4" class="text-center">
                <h2>Unauthorize Access! | 401</h2>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "Unauthorize"
    }
</script>

<style scoped>

</style>