<template>
    <main-layout>
        <v-container class="py-8 px-6" fluid>
            <v-row>
                <v-col>
                    <v-alert v-if="alert2.text" dense text :type="alert2.type">
                        {{alert2.text}}
                    </v-alert>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card class="pa-3">
                        <v-card-title>
                            Users List
                            <v-spacer></v-spacer>
                            <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Search"
                                    single-line
                                    hide-details
                            ></v-text-field>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table
                                    :headers="headers"
                                    :items="items"
                                    :search="search"
                                    sort-by="calories"
                            >
                                <template v-slot:top>
                                    <v-toolbar
                                            flat
                                    >
                                        <v-dialog
                                                v-model="dialog"
                                                max-width="500px"
                                        >
                                            <v-card>
                                                <v-card-title>
                                                    <span class="headline">{{ formTitle }}</span>
                                                </v-card-title>

                                                <v-card-text>
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="12"> <v-alert v-if="alert.text" dense text :type="alert.type">
                                                                {{alert.text}}
                                                            </v-alert></v-col>
                                                            <v-col cols="12" >
                                                                <v-text-field v-mask="'AAA-####'" v-model="editedItem.employee_id" label="ID No."></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" >
                                                                <v-text-field v-model="editedItem.firstname" label="First Name"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" >
                                                                <v-text-field v-model="editedItem.middlename" label="Middle Name"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" >
                                                                <v-text-field v-model="editedItem.lastname" label="Last Name"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" >
                                                                <v-text-field v-model="editedItem.email" label="Email"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" >
                                                                <v-text-field v-model="editedItem.designation" label="Designation"></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card-text>

                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="primary darken-1" text @click="close">
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn color="primary darken-1" text @click="save">
                                                        Save
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        <v-dialog v-model="dialogDelete" max-width="550px">
                                            <v-card>
                                                <v-card-title class="headline">Are you sure you want to delete this user? <i>(This action cannot be undone)</i></v-card-title>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="green darken-1" text @click="closeDelete">Cancel</v-btn>
                                                    <v-btn color="red darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-toolbar>
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <v-icon
                                            small
                                            color="primary"
                                            class="mr-2"
                                            @click="editItem(item)"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon
                                            small
                                            color="red"
                                            @click="deleteItem(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </template>
                                <template v-slot:no-data>
                                   No available data found
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </main-layout>
</template>

<script>
    import user from "../../api/user";
    export default {
        name: "User",
        data: () => ({
            dialog: false,
            dialogDelete: false,
            toEdit: null,
            toDelete: null,
            overlay: false,
            alert2:{},
            alert:{},
            headers: [
                {
                    text: 'ID No.',
                    align: 'start',
                    sortable: true,
                    value: 'employee_id',
                },
                { text: 'First Name', value: 'firstname' },
                { text: 'Middle Name', value: 'middlename' },
                { text: 'Last Name', value: 'lastname' },
                { text: 'Email', value: 'email' },
                { text: 'Designation', value: 'designation' },
                { text: 'Profile', value: 'profile_pic' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            items: [],
            search: null,
            editedIndex: -1,
            editedItem: {
                employee_id: '',
                firstname: '',
                middlename: '',
                lastname: '',
                email: '',
                designation: '',
                profile_pic: '',
                oldId: '',

            },
            defaultItem: {
                employee_id: '',
                firstname: '',
                middlename: '',
                lastname: '',
                email: '',
                designation: '',
                profile_pic: '',
                oldId: '',
            },
        }),

        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'New User Profile' : 'Edit User Profile'
            },
        },

        watch: {
            dialog (val) {
                val || this.close()
            },
            dialogDelete (val) {
                val || this.closeDelete()
            },
        },

        created () {
            this.initialize()
        },

        methods: {
            initialize () {
                user.auth()
                    .then(() =>{
                        user.get_all_users()
                            .then(response =>{
                                this.items = response.data;
                            })
                    })
            },

            editItem (item) {
                this.alert2 = {}
                this.toEdit = item.employee_id,
                this.editedIndex = this.items.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            deleteItem (item) {
                this.alert2 = {}
                this.toDelete = item.employee_id
                this.editedIndex = this.items.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            deleteItemConfirm () {
                this.overlay = true;
                const data = {
                    employee_id: this.toDelete,
                }
                user.auth()
                    .then(() => {
                        user.adminDelete(data).then(response => {
                            this.alert2 =
                                {
                                    type: response.data.type,
                                    text: response.data.message,
                                }
                            this.overlay = false;
                            this.initialize();
                        })
                    })
                this.items.splice(this.editedIndex, 1)
                this.closeDelete()
            },

            close () {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeDelete () {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            save () {
                if (this.editedIndex > -1) {
                    this.editedItem.oldId = this.toEdit;
                    const form = this.editedItem;
                    user.auth()
                        .then(() =>{
                            user.adminUpdate(form)
                                .then(response =>{
                                    if(response.data.type === 'error'){
                                        this.alert2 =
                                            {
                                                type: response.data.type,
                                                text: response.data.message,
                                            }
                                    }
                                    if(response.data.type === 'success'){
                                        this.alert2 =
                                            {
                                                type: response.data.type,
                                                text: response.data.message,
                                            }
                                        this.overlay = false;
                                        this.initialize();
                                        this.close()
                                    }
                                })
                        })
                } else {
                    this.items.push(this.editedItem)
                }
                this.close()
            },
        },
    }

</script>

<style scoped>

</style>